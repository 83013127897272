<template>
  <div class="form-group">
    <label for="select2">{{ namaLabelBrowse }}</label>
    <v-select
      label="nama"
      :options="arrayDataProgramStudi"
      v-model="localDataProgramStudi"
      @input="onSelectProgramStudi"
      :disabled="Boolean(disabled)"
      :clearable="false"
    >
      <template #search="{ attributes, events }">
        <input
          class="vs__search"
          :required="requiredProgramStudi"
          v-bind="attributes"
          v-on="events"
        />
      </template>
    </v-select>
  </div>
</template>
<script>
import ProgramStudiApi from "@/api/ProgramStudiApi.js";
/**
 * Component digunakan untuk melakukan browse Program Studi.
 * @example @/docs/ExampleBaseBrowseProgramStudi.md
 */
export default {
  name: "BaseBrowseProgramStudi",
  props: {
    /**
     * Nama Label pada Select2
     */
    namaLabelBrowse: {
      type: String,
      required: true,
    },
    /**
     * khusus set dummy data (digunakan pada documentation)
     * @type {Array<Object>}
     */
    dataDummy: {
      type: Array,
      default: () => [],
    },
    /**
     * Data Program Studi yang terpilih
     */
    dataProgramStudi: {
      type: Object,
      required: true,
    },
    /**
     * Flag true => {id: 0, nama:semua} sebagai data
     */
    boolSemuaProgramStudi: {
      type: Boolean,
      default: false,
    },
    /**
     * Flag true Prodi S1 dan S2
     */
    boolProgramStudiS1DanS2: {
      type: Boolean,
      default: false,
    },
    /**
     * Flag boleh TI (true => boleh, false => tidak boleh)
     */
    bolehTi: {
      type: Boolean,
      default: true,
    },
    /**
     * Flag is S1 (true => S1, false => S2)
     */
    isS1: {
      type: Boolean,
      default: true,
    },
    /**
     * Flag required
     */
    required: {
      type: Boolean,
      default: false,
    },
    /**
     * Flag enabled / disabled
     */
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      localDataProgramStudi: { ...this.dataProgramStudi },
      arrayDataProgramStudi: [],
    };
  },
  computed: {
    requiredProgramStudi: function () {
      if (this.required) {
        return (
          this.localDataProgramStudi && this.localDataProgramStudi.id === ""
        );
      }
      return false;
    },
  },
  watch: {
    dataProgramStudi: {
      handler: async function (newVal) {
        this.localDataProgramStudi = newVal;
      },
      deep: true,
    },
  },
  created: async function () {
    await this.getDataProgramStudi();
  },
  methods: {
    /**
     * Function untuk mendapatkan Daftar Data Program Studi
     * @public
     * @event getDataProgramStudi
     * @param {-}
     */
    getDataProgramStudi: async function () {
      if (this.dataDummy.length > 0) {
        this.arrayDataProgramStudi = [...this.dataDummy];
      } else {
        try {
          var resProgramStudi;
          if (this.boolProgramStudiS1DanS2) {
            resProgramStudi =
              await ProgramStudiApi.getAllProgramStudiByIdJenjang();
          } else if (this.isS1) {
            resProgramStudi =
              await ProgramStudiApi.getAllProgramStudiByIdJenjang(3);
          } else {
            resProgramStudi =
              await ProgramStudiApi.getAllProgramStudiByIdJenjang(4);
          }
          if (resProgramStudi.status == 200) {
            let dataProgramStudi = resProgramStudi.data.data;
            let temp = dataProgramStudi.map((it) => {
              return {
                id: it.id,
                nama: it.nama_indonesia,
              };
            });
            if (!this.boolProgramStudiS1DanS2 && this.isS1 && !this.bolehTi) {
              let ti = "Teknik Informatika";
              this.arrayDataProgramStudi = temp.filter((it) => it.nama != ti);
            } else {
              if (this.boolSemuaProgramStudi) {
                this.arrayDataProgramStudi = [
                  { id: 0, nama: "Semua" },
                  ...temp,
                ];
              } else {
                this.arrayDataProgramStudi = [...temp];
              }
            }
          } else {
            let errorMessage = resProgramStudi.data.error.message;
            throw errorMessage;
          }
        } catch (err) {
          this.flash(err, "error");
        }
      }
    },
    /**
     * Trigger ketika ada perubahan selected dataProgramStudi
     * @public
     * @event onSelectProgramStudi
     * @param {-}
     */
    onSelectProgramStudi: async function () {
      /**
       * Update parent selected dataProgramStudi.
       * @event update:dataProgramStudi
       * @property {Object} dataProgramStudi - Selected dataProgramStudi
       */
      this.$emit("update:dataProgramStudi", this.localDataProgramStudi);
    },
  },
};
</script>
