import axios from '@/axios';
// idJenjang = {1 => D1, 2 => D3, 3 => S1, 4 => S2}

async function getAllProgramStudiByIdJenjang(idJenjang) {
    let endpoint = `program-studi?status_aktif=1`
    if (idJenjang) {
        endpoint += `&id_jenjang_studi=${idJenjang}`
    }
    endpoint += "&order_by=kode.asc&limit=all"
    let responseProgramStudi = await axios.get(endpoint)
    return responseProgramStudi
}
export default {
    getAllProgramStudiByIdJenjang
}