<template>
  <div class="form-group">
    <label for="select2">{{ namaLabelBrowse }}</label>
    <v-select
      label="nama"
      :options="arrayDataWaktuKuliah"
      v-model="localDataWaktuKuliah"
      @input="onSelectWaktuKuliah"
      :disabled="Boolean(disabled)"
      :clearable="false"
    >
      <template #search="{ attributes, events }">
        <input
          class="vs__search"
          :required="requiredWaktuKuliah"
          v-bind="attributes"
          v-on="events"
        />
      </template>
    </v-select>
  </div>
</template>
<script>
import ShiftPerkuliahanApi from "@/api/ShiftPerkuliahanApi.js";
/**
 * Component digunakan untuk melakukan browse Waktu Kuliah.
 * @example @/docs/ExampleBaseBrowseWaktuKuliah.md
 */
export default {
  name: "BaseBrowseWaktuKuliah",
  props: {
    /**
     * Nama Label pada Select2
     */
    namaLabelBrowse: {
      type: String,
      required: true,
    },
    /**
     * Data Waktu Kuliah yang terpilih
     */
    dataWaktuKuliah: {
      type: Object,
      required: true,
    },
    /**
     * khusus set dummy data (digunakan pada documentation)
     * @type {Array<Object>}
     */
    dataDummy: {
      type: Array,
      default: () => [],
    },
    /**
     * Flag data Semua saja
     */
    boolSemua: {
      type: Boolean,
      default: false,
    },

    /**
     * Flag Sore saja
     */
    isSoreOnly: {
      type: Boolean,
      default: false,
    },
    /**
     * Flag required
     */
    required: {
      type: Boolean,
      default: false,
    },
    /**
     * Flag enabled / disabled
     */
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      localDataWaktuKuliah: { ...this.dataWaktuKuliah },
      arrayDataWaktuKuliah: [],
    };
  },
  computed: {
    requiredWaktuKuliah: function () {
      if (this.required) {
        return this.localDataWaktuKuliah && this.localDataWaktuKuliah.id === "";
      }
      return false;
    },
  },
  watch: {
    dataWaktuKuliah: {
      handler: async function (newVal) {
        this.localDataWaktuKuliah = newVal;
      },
      deep: true,
    },
  },
  created: async function () {
    await this.getDataWaktuKuliah();
  },
  methods: {
    /**
     * Function untuk mendapatkan Daftar Data Waktu Kuliah
     * @public
     * @event getDataWaktuKuliah
     * @param {-}
     */
    getDataWaktuKuliah: async function () {
      if (this.dataDummy.length > 0) {
        this.arrayDataWaktuKuliah = [...this.dataDummy];
      } else {
        try {
          let resWaktuKuliah = await ShiftPerkuliahanApi.getAllWaktuKuliah();
          if (resWaktuKuliah.status == 200) {
            let dataWaktuKuliah = resWaktuKuliah.data.data;
            let temp = dataWaktuKuliah.map((it) => {
              return {
                id: it.id,
                nama: it.nama,
              };
            });
            if (this.isSoreOnly) {
              this.arrayDataWaktuKuliah = temp.filter(
                (it) => it.nama == "Sore"
              );
            } else {
              if (this.boolSemua) {
                this.arrayDataWaktuKuliah = [{ id: 0, nama: "Semua" }, ...temp];
              } else {
                this.arrayDataWaktuKuliah = [...temp];
              }
            }
          } else {
            let errorMessage = resWaktuKuliah.data.error.message;
            throw errorMessage;
          }
        } catch (err) {
          this.flash(err, "error");
        }
      }
    },
    /**
     * Trigger ketika ada perubahan selected dataWaktuKuliah
     * @public
     * @event onSelectWaktuKuliah
     * @param {-}
     */
    onSelectWaktuKuliah: async function () {
      /**
       * Update parent selected dataWaktuKuliah.
       * @event update:dataWaktuKuliah
       * @property {Object} dataWaktuKuliah - Selected dataWaktuKuliah
       */
      this.$emit("update:dataWaktuKuliah", this.localDataWaktuKuliah);
    },
  },
};
</script>
