<template>
  <div class="laporan-pendaftaran-usm-harian">
    <CCard>
      <CCardHeader> Laporan Pendaftar USM Harian </CCardHeader>
      <CCardBody>
        <CRow>
          <CCol sm="6">
            <BaseVueSelect
              nama-label-browse="Tahun Ajaran"
              :data-selected.sync="tahunAjaran"
              :array-options="arrayDataTahunAjaran"
              :disabled="false"
            />
          </CCol>
        </CRow>
        <CRow>
          <CCol sm="6">
            <BaseVueSelect
              nama-label-browse="Status Pembayaran"
              :data-selected.sync="statusPembayaran"
              :array-options="arrayStatusPembayaran"
              :disabled="false"
              :required="!statusPembayaran"
            />
          </CCol>
          <CCol sm="6">
            <BaseVueSelect
              nama-label-browse="Status Final Dokumen"
              :data-selected.sync="statusFinalDokumen"
              :array-options="arrayStatusFinalDokumen"
              :disabled="false"
              :required="!statusFinalDokumen"
            />
          </CCol>
        </CRow>
        <CRow>
          <CCol sm="6">
            <BaseBrowseProgramStudi
              nama-label-browse="Program Studi"
              :data-program-studi.sync="programStudi"
              :disabled="false"
              :required="true"
              :bool-semua-program-studi="true"
              :bool-program-studi-s1-dan-s2="true"
            />
          </CCol>
          <CCol sm="6">
            <BaseBrowseWaktuKuliah
              nama-label-browse="Waktu Kuliah"
              :data-waktu-kuliah.sync="waktuKuliah"
              :bool-semua="true"
              :required="true"
              :disabled="false"
            />
          </CCol>
        </CRow>
        <CRow>
          <CCol sm="6">
            <div class="form-group">
              <label for="tanggal-registrasi-mulai"
                >Dari Tanggal Registrasi</label
              >
              <input
                id="tanggal-registrasi-mulai"
                class="form-control"
                type="date"
                v-model="tanggalRegistrasiMulai"
                required
              />
            </div>
          </CCol>
          <CCol sm="6">
            <div class="form-group">
              <label for="tanggal-registrasi-selesai"
                >Sampai Tanggal Registrasi</label
              >
              <input
                id="tanggal-registrasi-selesai"
                class="form-control"
                type="date"
                v-model="tanggalRegistrasiSelesai"
                required
              />
            </div>
          </CCol>
        </CRow>

        <CRow>
          <CCol sm="12" class="text-right mt-3">
            <b-button class="mr-2" variant="primary" @click="onClickFilter">
              <b-icon icon="filter" class="mr-2" /> Filter
            </b-button>
            <b-button variant="primary" @click="onClickExport">
              <b-icon icon="printer" class="mr-2" />
              Export
            </b-button>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
    <CCard v-if="showLaporan">
      <CCardHeader>Laporan Daftar Ulang</CCardHeader>
      <CCardBody>
        <CRow class="mb-3">
          <CCol sm="6"> <b>Jumlah Pendaftar:</b> {{ jumlahPendaftar }} </CCol>
          <CCol sm="6"> <b>Total Pembayaran:</b> {{ totalPembayaran }} </CCol>
        </CRow>
        <BaseSmartTable
          :data-columns="headerTable"
          :data-rows="detailTable"
          :smart-table-filter="smartTableFilter"
          :req-find-done="reqTableDone"
        />
      </CCardBody>
    </CCard>
  </div>
</template>
<script>
import BaseVueSelect from "@/components/base/BaseVueSelect.vue";
import BaseSmartTable from "@/components/base/BaseSmartTable.vue";
import BaseBrowseWaktuKuliah from "@/components/base/BaseBrowseWaktuKuliah.vue";
import BaseBrowseProgramStudi from "@/components/base/BaseBrowseProgramStudi.vue";

import KonfigurasiApi from "@/api/KonfigurasiApi.js";
import UserOldApi from "@/api/UserOldApi.js";

export default {
  name: "LaporanPendaftarUSMHarian",
  components: {
    BaseVueSelect,
    BaseSmartTable,
    BaseBrowseWaktuKuliah,
    BaseBrowseProgramStudi,
  },
  data() {
    return {
      arrayDataTahunAjaran: [],
      tahunAjaran: "",

      arrayStatusPembayaran: ["Semua", "Sudah Bayar", "Belum Bayar"],
      statusPembayaran: "Semua",

      arrayStatusFinalDokumen: ["Semua", "Sudah Final", "Belum Final"],
      statusFinalDokumen: "Semua",

      programStudi: { id: 0, nama: "Semua" },
      waktuKuliah: { id: 0, nama: "Semua" },

      jumlahPendaftar: 0,
      totalPembayaran: 0,

      tanggalRegistrasiMulai: "",
      tanggalRegistrasiSelesai: "",

      headerTable: [
        { key: "no", value: "No" },
        { key: "no_usm", value: "No USM" },
        { key: "tanggal", value: "Tanggal Registrasi" },
        { key: "nama", value: "Nama" },
        { key: "alamat", value: "Alamat" },
        { key: "no_telp", value: "No Telp" },
        { key: "hp", value: "HP" },
        { key: "waktu_kuliah", value: "Waktu Kuliah" },
        { key: "nominal_registrasi", value: "Nominal Registrasi" },
        { key: "program_studi", value: "Program Studi" },
      ],
      detailTable: [],
      reqTableDone: false,

      showLaporan: false,
    };
  },
  async created() {
    this.tanggalRegistrasiMulai = new Date().toISOString().substring(0, 10);
    this.tanggalRegistrasiSelesai = new Date().toISOString().substring(0, 10);
    await this.getDataTahunAjaran();
    this.setDefaultTahunAjaran();
  },
  methods: {
    doNothing: function () {},
    setDefaultTahunAjaran: function () {
      let jlhDataTahunAjaran = this.arrayDataTahunAjaran.length;
      if (jlhDataTahunAjaran > 0) {
        this.tahunAjaran = this.arrayDataTahunAjaran[jlhDataTahunAjaran - 1];
      }
    },
    getDataTahunAjaran: async function () {
      try {
        let jenjang = 1;
        let resTahunAjaran =
          await KonfigurasiApi.getAllKonfigurasiTahunAjaranByJenjang(jenjang);
        if (resTahunAjaran.status == 200) {
          let dataTahunAjaran = resTahunAjaran.data.data;
          let temp = dataTahunAjaran.map((it) => {
            return it.tahun_ajaran;
          });
          this.arrayDataTahunAjaran = [...temp];
        } else {
          let errorMessage = resTahunAjaran.data.error.message;
          throw errorMessage;
        }
      } catch (err) {
        return Promise.reject(err);
        // this.flash(err, "error");
      }
    },
    smartTableFilter: function (filterValue, row) {
      filterValue = filterValue.toLowerCase();
      return (
        row.tanggal.toLowerCase().includes(filterValue) ||
        row.no_usm.toLowerCase().includes(filterValue) ||
        row.nama.toLowerCase().includes(filterValue)
      );
    },
    onClickFilter: async function () {
      this.showLaporan = true;
      this.reqTableDone = false;
      this.detailTable = [];
      try {
        let idStatusPembayaran =
          this.statusPembayaran == "Semua"
            ? -1
            : this.statusPembayaran == "Sudah Bayar"
            ? 1
            : 0;

        let idStatusFinal =
          this.statusFinalDokumen == "Semua"
            ? -1
            : this.statusFinalDokumen == "Sudah Final"
            ? 1
            : 0;

        let filterObject = {
          tahunAjaran: this.tahunAjaran,
          tanggalAwalRegistrasi: this.tanggalRegistrasiMulai,
          tanggalAkhirRegistrasi: this.tanggalRegistrasiSelesai,
          statusPembayaran: idStatusPembayaran,
          statusFinal: idStatusFinal,
          idProgramStudi: this.programStudi.id,
          idShiftPerkuliahan: this.waktuKuliah.id,
        };
        let responseDaftarMahasiswa = await UserOldApi.getMahasiswaMendaftar(
          filterObject
        );
        if (responseDaftarMahasiswa.status == 200) {
          let daftarMahasiswa = responseDaftarMahasiswa.data.data;
          this.jumlahPendaftar = responseDaftarMahasiswa.data._total;
          this.totalPembayaran = this.$_generalMixin_convertMoney(
            responseDaftarMahasiswa.data.total_pembayaran
          );

          let index = 1;
          this.detailTable = daftarMahasiswa.map((mahasiswa) => {
            return {
              no: index++,
              tanggal: mahasiswa.tanggal_pembayaran || "-",
              no_usm: mahasiswa.nomor_usm || "-",
              nama: mahasiswa.nama || "-",
              alamat: mahasiswa.alamat || "-",
              no_telp: mahasiswa.nomor_telepon || "-",
              hp: mahasiswa.nomor_handphone || "-",
              nominal_registrasi: this.$_generalMixin_convertMoney(
                mahasiswa.biaya_registrasi
              ),
              waktu_kuliah: mahasiswa.shift_perkuliahan,
              program_studi: mahasiswa.program_studi,
            };
          });
        } else {
          let errorMessage = responseDaftarMahasiswa.data.error.message;
          throw errorMessage;
        }
      } catch (err) {
        this.flash(err, "error");
      }
      this.reqTableDone = true;
    },
    onClickExport: function () {
      let idStatusPembayaran =
        this.statusPembayaran == "Semua"
          ? -1
          : this.statusPembayaran == "Sudah Bayar"
          ? 1
          : 0;

      let idStatusFinal =
        this.statusFinalDokumen == "Semua"
          ? -1
          : this.statusFinalDokumen == "Sudah Final"
          ? 1
          : 0;

      let filterObject = {
        tahunAjaran: this.tahunAjaran,
        tanggalAwalRegistrasi: this.tanggalRegistrasiMulai,
        tanggalAkhirRegistrasi: this.tanggalRegistrasiSelesai,
        statusPembayaran: idStatusPembayaran,
        statusFinal: idStatusFinal,
        idProgramStudi: this.programStudi.id,
        idShiftPerkuliahan: this.waktuKuliah.id,
      };

      let token = this.$store.getters["auth/getToken"];
      let url = UserOldApi.getMahasiswaMendaftarExport(filterObject, token);
      let link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "file.xlsx");
      link.click();
    },
  },
};
</script>
<style lang="scss">
.laporan-pendaftaran-usm-harian {
}
</style>