import axios from '@/axios';

function endpointMahasiswaMendaftar(
    {
        tahunAjaran,
        tanggalAwalRegistrasi,
        tanggalAkhirRegistrasi,
        statusPembayaran = -1,
        statusFinal = -1,
        idProgramStudi,
        idShiftPerkuliahan,
    }
) {
    let endpoint = `old/user?tahun_ajaran=${tahunAjaran}&waktu_buat_awal=${tanggalAwalRegistrasi}&waktu_buat_akhir=${tanggalAkhirRegistrasi}`;

    if (statusPembayaran >= 0) {
        endpoint += `&status_pembayaran=${statusPembayaran}`
    }

    if (statusFinal >= 0) {
        endpoint += `&status_final=${statusFinal}`
    }

    if (idProgramStudi > 0) {
        endpoint += `&id_program_studi=${idProgramStudi}`
    }

    if (idShiftPerkuliahan > 0) {
        endpoint += `&id_shift_perkuliahan=${idShiftPerkuliahan}`
    }

    endpoint += `&limit=all`
    return endpoint
}

async function getMahasiswaMendaftar(filter) {
    let endpoint = endpointMahasiswaMendaftar(filter)

    let resAllUsers = await axios.get(endpoint);
    return resAllUsers;
}

function getMahasiswaMendaftarExport(filter, token) {
    let endpoint = endpointMahasiswaMendaftar(filter)
    return `${axios.defaults.baseURL}/${endpoint}&token=${token}&export=1`;
}

export default {
    getMahasiswaMendaftar,
    getMahasiswaMendaftarExport
}